/**
 * @author Erdem Arslan
 */

var mt = mt || {};

(function (app, $) {
    app.namespace = 'mt';

    app.$objs = {};
    app.modules = [];

    $.fn.exists = function (callback) {
        var args = [].slice.call(arguments, 1);
        if (this.length) {
            callback.call(this, args);
        }
        return this;
    }

    app.registerInit = function (module) {
        app.modules.push(module);
    };

    app.plugged = [
        {
            func: 'tabview',
            target: '.tab-view',
            params: {
                button: '.tab-view-button',
                content: '.tab-view-content'
            }
        },
        {
            func: 'rotator',
            target: '#hero',
            params: {
                transition: 'fade',
                slides: '.hero-banner-item',
                bullets: true,
                slideDelay: 5000
            }
        },
        {
            func: 'rotator',
            target: '#content-slider',
            params: {
                transition: 'fade',
                slides: '.content-slider-item',
                bullets: true,
                slideDelay: 5000
            }
        }
    ];

    var mediaQuery = 768,
        pressSlider = {
            func: 'rotator',
            target: '#presskits-article',
            params: {
                transition: 'horizontal',
                slides: '.article-wrap',
                bullets: true,
                slideDelay: 5000
            }
        },
        infoSlider = {
            func: 'rotator',
            target: '#infographic-article',
            params: {
                transition: 'horizontal',
                slides: '.article-wrap',
                bullets: true,
                slideDelay: 5000
            }
        },
        videoSlider = {
            func: 'rotator',
            target: '#video-article',
            params: {
                transition: 'horizontal',
                slides: '.article-wrap',
                bullets: true,
                slideDelay: 5000
            }
        };
        /*
        youtubeSlider = {
            func: 'rotator',
            target: '#youtube-article',
            params: {
                transition: 'horizontal',
                slides: '.article-wrap',
                bullets: true,
                slideDelay: 5000
            }
        },
        twitterSlider = {
            func: 'rotator',
            target: '#twitter-feed-Turkcell',
            params: {
                transition: 'horizontal',
                slides: '.fts-tweeter-wrap',
                bullets: true,
                slideDelay: 5000
            }
        },
        instagramSlider = {
            func: 'rotator',
            target: '#instagram-article',
            params: {
                transition: 'horizontal',
                slides: '.article-wrap',
                bullets: true,
                slideDelay: 5000
            }
        }*/

    if (window.innerWidth > mediaQuery) {
        app.plugged.push(pressSlider);
        app.plugged.push(infoSlider);
        app.plugged.push(videoSlider);
        // app.plugged.push(youtubeSlider);
        // app.plugged.push(twitterSlider);
        // app.plugged.push(instagramSlider);
    }

    app.init = function () {
        app.$objs.body = $('body');
        app.titleWrite();
        app.scroll();
        app.dropdown();
        app.toggler();
        app.validation();
        app.filters();
        app.changeView();
        app.bindPlugins();
    };

    app.initModules = function () {
        if (app.modules.length > 0) {
            app.modules.forEach(function (module) {
                if (typeof app[module] !== 'undefined' && !app[module].isInitialized()) {
                    app[module].init();
                }
            })
        }
    };

    app.bindPlugins = function () {
        $(app.plugged).each(function () {
            var e = this, p;

            if (typeof $.fn[e.func] !== 'function') {
                return
            }
            if (typeof e.params !== 'undefined') {
                p = e.params
            }
            $(e.target)[e.func](p);
        })
    };

    app.titleWrite = function () {
        var title = $('[data-id="subcorps-list"]').find(".subcorps-logo");

        for(var i = 0; i < title.length; i++){
            var titleTxt = $(title[i]).attr("title");
            $(title[i]).find('.post-title').html(titleTxt);
        }
    };

    // on scroll event
    app.scroll = function () {
        var className = 'is-scrolled',
            headerheight = $('#header').outerHeight();

        $(window).scroll(function () {
            if ($(this).scrollTop() > 1) {
                $('#header').addClass(className);
                // $('#main').css('margin-top',headerheight);
            } else {
                $('#header').removeClass(className);
                // $('#main').css('margin-top','');
            }
        })
    };

    // simple form toggle
    app.dropdown = function () {
        var isActive = 'is-active',
            button = $('[data-role="button"]'),
            content = $('[data-role="content"]')

        $(button).exists(function () {
            button.on('click', function (event) {
                event.preventDefault()

                if ($(this).hasClass(isActive)) {
                    $(this).removeClass(isActive).next(content).removeClass(isActive)
                    $(this).parents('body').find('.overlay').remove()
                    $(this).parents('html').removeClass('is-fixed')
                } else {
                    $(this).addClass(isActive).next(content).addClass(isActive)
                    $(this).parents('body').append('<div class="overlay"></div>')
                    $(this).parents('html').addClass('is-fixed')
                }
            })
        })
    }

    // form validation
    app.validation = function () {
        $('form.validate').exists(function () {
            $('form.validate').validate({
                rules: {
                    subscribe: {
                        required: true,
                        email: true,
                        minlength: 5
                    }
                },
                messages: {
                    subscribe: {
                        required: 'Lütfen bu alanı boş bırakmayınız.',
                        email: 'Hatalı bir eposta girdiniz!',
                        minlength: 'En az 5 karakterden oluşan bir eposta giriniz.'
                    }
                },
                submitHandler: function (form) {
                    form.submit()
                }
            })
        })
    }

    app.toggler = function () {
        var className = 'is-toggle'

        $('#content-toggler').on('click', function () {
            if ($(this).hasClass(className)) {
                $(this).removeClass(className)
                $(this).parents('section').prev('#content-slider').removeClass(className)
            } else {
                $(this).addClass(className)
                $(this).parents('section').prev('#content-slider').addClass(className)
            }
        })
    }

    app.filters = function () {

        // init Isotope
        var $grid = $('.grid').isotope({
            itemSelector: '.filter-item',
            layoutMode: 'fitRows'
        })

        // bind filter button click
        $('#filter-buttons').on('click', 'button', function () {
            var filterValue = $(this).attr('data-filter')
            $grid.isotope({filter: filterValue})
        })

        // change is-checked class on buttons
        $('#filter-buttons').each(function (i, buttonGroup) {
            var $buttonGroup = $(buttonGroup)
            $buttonGroup.on('click', 'button', function () {
                $buttonGroup.find('.is-checked').removeClass('is-checked')
                $(this).addClass('is-checked')
            })
        })
    }

    app.changeView = function() {
        var gridBtn = jQuery('#grid-view'),
            listBtn = jQuery('#list-view'),
            wrapper = jQuery('[data-view="grid"]'),
            parent  = jQuery('#change-post-view'),
            tabPanel = jQuery('.tab-view-content current'),
            tabPanelHtml = tabPanel.html();
        
        gridBtn.on('click', function(e){
            e.preventDefault();
            parent.removeClass('grid-visible').addClass('list-visible');
            wrapper.attr('data-view', 'list');
        });

        listBtn.on('click', function(e){
            e.preventDefault();
            parent.removeClass('list-visible').addClass('grid-visible');
            wrapper.attr('data-view', 'grid');
        })
    }

})(mt, jQuery)

;
(function ($) {
    $(function () {
        mt.init()
    })
})(jQuery)
